import React from "react"
import { Transition, Spring } from "react-spring/renderprops"
import Header from "./header"

const Hero = props => {
  console.log("HERO PROPS", props)

  return (
    <section className="hero">
      <Header path={props.path} />
      <div className="container-fluid px-5 h-100">
        <div className="row h-100 main-row">
          <Transition
            config={{ friction: 50 }}
            items={[1]}
            from={{ opacity: 0, transform: "scale(1.2) " }}
            enter={{ opacity: 1, transform: "scale(1) " }}
            leave={{ opacity: 0 }}
          >
            {show => props => (
              <div
                style={props}
                className="col-md-12 col-12 d-md-flex d-lg-flex col-lg-6 design_svg"
              ></div>
            )}
          </Transition>

          <Spring
            config={{ delay: 200, friction: 50 }}
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
          >
            {props => (
              <div
                style={props}
                className="col-12 col-lg-6 d-flex align-items-center "
              >
                <div className="row h-100 justify-content-center align-items-lg-center right_content">
                  <div className="col-12 col-md-12 col-xl-10 ml-xl-5">
                    <h1 className="heading1 heading mb-medium">
                    Serving Excellence in Digital Space
                    </h1>
                    <Spring
                      config={{ delay: 600, friction: 100 }}
                      from={{ opacity: 0 }}
                      to={{ opacity: 1 }}
                    >
                      {props => (
                        <p style={props} className="heading2 hero__description">
                          Bring your ideas to life & start your application
                          development hassle free, rooted along the latest
                          technologies and frameworks to ensure the best, secure
                          and optimized applications.
                        </p>
                      )}
                    </Spring>
                  </div>
                </div>
              </div>
            )}
          </Spring>
        </div>
      </div>
    </section>
  )
}

export default Hero
