import React, { useLayoutEffect, useState } from "react"
import Layout from "../components/layout"
import "../styles/main.scss"
import {Link} from 'gatsby';
import Hero from "../components/Hero"
import Header from "../components/header"
import {
  MdCode,
  MdLaptopChromebook,
  MdArrowForward,
  MdAndroid,
  MdFormatQuote,
} from "react-icons/md"

import  {FiPenTool} from 'react-icons/fi'
import seoIcon from "../images/seo.svg"
import digitalMarket from "../images/digitalmarket.svg"
import graphicDesign from "../images/graphicdesign.svg"
import webDesign from "../images/webdesign.svg"
import softDevelopment from "../images/softwaredevelopment.svg"
import webDevelopment from "../images/webdevelopment.svg"


import Footer from "../components/footer"

import { IoLogoBuffer } from "react-icons/io"
import { Spring } from "react-spring/renderprops"
import VisibilitySensor from "react-visibility-sensor"
import { FaDiceD20 } from "react-icons/fa"
import { IoIosArrowDropright, IoIosArrowDropleft } from "react-icons/io"

import Tabs from "antd/es/tabs"
import "antd/es/tabs/style/css"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import traveleasygo from "../images/traveleasygo.png"

const { TabPane } = Tabs
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
}

// import VisibilitySensor from "../components/VisiblilitySensor"

const IndexPage = props => {
  let no_of_slides = 2

  const [slides, setSlide] = useState(2)
  const [slideloc, setSlideloc] = useState("left")
  const [visible, setVisible] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)

  function useWindowSize() {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
        if (window.innerWidth < 992) {
          setSlide(1)
          setSlideloc("top")
        } else {
          setSlide(2)
          setSlideloc("left")
        }

        if (window.innerWidth <= 576) {
          setSlide(1)
          setSlideloc("top")
        }
        // if (window.innerWidth > 992) {
        //   setSlide(1)
        //   setSlideloc("left")
        // }
      }
      window.addEventListener("resize", updateSize)
      updateSize()
      return () => window.removeEventListener("resize", updateSize)
    }, [])
    return size
  }

  const [width, height] = useWindowSize()
  // console.log("WIDTH", width, "HEIGHT", height, "SLIDE", slides)

  return (
    <Layout>
      <Hero path={props.path} />

      <section className="services">
        <div className="row justify-content-center services__heading">
          <h1 className="heading-title">Our services</h1>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-md-5 mb-5  mb-lg-0 col-md-12 col-sm-12 service">
              <div className="service__card">
                <div className="icon mb-small">
                <img src={webDevelopment} className="service__icon" />
                </div>
                <h2 className="service__heading heading2 mb-small">
                  Web Development
                </h2>
                <p className="service__description mb-small">
                Web development is one of our primary services that we offer understanding our clients’ businesses and what exactly their websites need. 


                </p>
                <Link to="/services">
                <button className="service__btn small_btn">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button>
                </Link>
                
              </div>
            </div>

            <div className="col-lg-4 mb-5  mb-md-5 mb-lg-0 col-md-12 col-sm-12 service">
              <div className="service__card">
                <div className="icon mb-small">
                <img src={softDevelopment} className="service__icon" />
                </div>
                <h2 className="service__heading mb-small heading2">
                  Software Development
                </h2>
                <p className="service__description mb-small">
                Software development is one of our core services that is offered after understanding and analyzing the needs of the clients’ companies.
                </p>
                <Link to="/services">
                <button className="service__btn small_btn">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button>
                </Link>
               
              </div>
            </div>

            <div className="col-lg-4  mb-5  mb-md-5  mb-lg-0 col-md-12 col-sm-12 service">
              <div className="service__card">
                <div className="icon mb-small">
                <img src={webDesign} className="service__icon" />
                </div>
                <h2 className="service__heading mb-small heading2">
                  Web Design
                </h2>
                <p className="service__description mb-small">
                Web design is our area of strength where our designers deliver the best of their creativity. We aim to keep the web page visitors engaged and stick to the pages we create for our customers. 
                </p>
                <Link to="/services">
                <button className="service__btn small_btn">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button>
                </Link>
               
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-4 mb-md-5 mb-5  mb-lg-0 col-md-12 col-sm-12 service">
              <div className="service__card">
                <div className="icon mb-small">
                <img src={graphicDesign} className="service__icon" />
                </div>
                <h2 className="service__heading heading2 mb-small">
                  Graphic Design
                </h2>
                <p className="service__description mb-small">
                Graphic design is an integral part of our activity that we offer by creating the quality design as required by our clients.


                </p>
                <Link to="/services">
                <button className="service__btn small_btn">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button>
                </Link>
               
              </div>
            </div>

            <div className="col-lg-4 mb-5  mb-md-5 mb-lg-0 col-md-12 col-sm-12 service">
              <div className="service__card">
                <div className="icon mb-small">
                <img src={seoIcon} className="service__icon" />

                  {/* <seoIcon className="service__icon" /> */}
                </div>
                <h2 className="service__heading mb-small heading2">
                 Search Engine Optimization(SEO)
                </h2>
                <p className="service__description mb-small">
                Search Engine Optimization (SEO) needs in-depth knowledge and we are all set to deliver the best to our clients in this segment.
                </p>
                <Link to="/services">
                <button className="service__btn small_btn">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button>
                </Link>
               
              </div>
            </div>

            <div className="col-lg-4  mb-5  mb-md-5  mb-lg-0 col-md-12 col-sm-12 service">
              <div className="service__card">
                <div className="icon mb-small">
                <img src={digitalMarket} className="service__icon" />
                </div>
                <h2 className="service__heading mb-small heading2">
                  Digital Marketing
                </h2>
                <p className="service__description mb-small">
                Digital marketing is the most important aspect of our business offerings where we offer our clients from scratch to the end.
                </p>
                <Link to="/services">
                <button className="service__btn small_btn">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button>
                </Link>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 6322.7 1041"
        className="svg-bg"
      >
        <path
          className="st0"
          d="M5600.8,594.2c-205.7,146.8-315.5,217.4-562.7,217.4c-247.2,0-357-70.6-562.7-217.4
	c-228.8-163.3-542.2-387.1-1130.8-387.1S2442.8,430.8,2214,594.2c-205.7,146.8-315.5,217.4-562.6,217.4
	c-247.2,0-357-70.6-562.6-217.4C873.3,440.4,582.9,233.1,58.4,209.4C3.9,206.9-42,239.3-42,279.7v1073.6v70h6364.7V924.4V248.1
	C5984.9,320.2,5769.8,473.6,5600.8,594.2z"
        />
      </svg>

      <section className="working overflow-hidden">
        <div className="row justify-content-center">
          <h1 className="heading-title">Our working</h1>
        </div>

        <div className="container working__main">
          <div className="row working__row">
            <VisibilitySensor
              onChange={visible => setVisible(visible)}
              active={!visible}
            >
              {({ isVisible }) => (
                <Spring
                  config={{ tension: 200, friction: 100 }}
                  from={{
                    transform: isVisible
                      ? "translateX(10rem)"
                      : "translateX(0rem)",
                  }}
                  to={{
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible
                      ? "translateX(0rem)"
                      : "translateX(0rem)",
                  }}
                >
                  {props => (
                    <div
                      style={props}
                      className="col-sm-12 col-md-12 col-12 col-lg-6 working__left"
                    ></div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            <div className="col-sm-12 col-md-12 col-12 col-lg-6 working__right">
              <div className="working__rightbox">
                <div className="icon mb-medium align-self-lg-start">
                  <IoLogoBuffer className="service__icon working__icon " />
                </div>

                <h2 className="service__heading mb-small working__heading">
                  Formation Of The Idea
                </h2>
                <p className="service__description mb-small working__description">
                We are here to think out-of-the-box for every activity that we offer. We first try to understand the business objectives of our clients and offer them the exact solutions needed for their business benefits. Our team of experts for different activities brings innovative ideas to the plate to help our clients meet their goals.
                </p>
                {/* <button className="service__btn working__btn small_btn">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button> */}
              </div>
            </div>
          </div>

          <div className="row working__row">
            <VisibilitySensor
              onChange={visible => setVisible2(visible)}
              active={!visible2}
            >
              {({ isVisible }) => (
                <Spring
                  config={{ tension: 200, friction: 100 }}
                  to={{
                    opacity: isVisible ? 1 : 0,
                    // transform: isVisible
                    //   ? "translateX(-5rem)"
                    //   : "translateX(0rem)",
                  }}
                >
                  {props => (
                    <div
                      style={props}
                      className="col-sm-12 col-md-12 col-12 col-lg-6 d-md-block d-lg-none working__svg2"
                    ></div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
            <div className="col-sm-12 col-md-12 col-12 col-lg-6 working__right">
              <div className="working__leftbox mt-5">
                <div className="icon mb-medium align-self-start ">
                  <FaDiceD20 className="service__icon working__icon " />
                </div>

                <h2 className="service__heading mb-small working__heading align-self-start">
                 Making Ideas Real
                </h2>
                <p className="service__description align-self-start mb-small working__description ">
                Our dedicated team works relentlessly to create unique ideas for each of our clients and then makes those ideas real. It is our focus, hard work, and continuous effort to make every project successful motivate us to innovate ideas for our clients, and give those ideas the shape in reality.
                </p>
                {/* <button className="service__btn working__btn small_btn  ">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button> */}
              </div>
            </div>

            <VisibilitySensor
              onChange={visible => setVisible3(visible)}
              active={!visible3}
            >
              {({ isVisible }) => (
                <Spring
                  config={{ tension: 200, friction: 100 }}
                  to={{
                    opacity: isVisible ? 1 : 0,
                    // transform: isVisible
                    //   ? "translateX(-5rem)"
                    //   : "translateX(0rem)",
                  }}
                >
                  {props => (
                    <div
                      style={props}
                      className="col-sm-12 col-md-12 col-12 col-lg-6 d-md-none d-lg-block working__svg2"
                    ></div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>
          </div>

          <div className="row working__row">
            <VisibilitySensor
              onChange={visible => setVisible2(visible)}
              active={!visible2}
            >
              {({ isVisible }) => (
                <Spring
                  config={{ tension: 200, friction: 100 }}
                  to={{
                    opacity: isVisible ? 1 : 0,
                    // transform: isVisible,
                    // // ? "translateX(5rem)"
                    // : "translateX(0rem)",
                  }}
                >
                  {props => (
                    <div
                      style={props}
                      className="col-sm-12 col-md-12 col-12 col-lg-6 working__svg3"
                    ></div>
                  )}
                </Spring>
              )}
            </VisibilitySensor>

            <div className="col-sm-12 col-md-12 col-12 col-lg-6 working__right">
              <div className="working__rightbox">
                <div className="icon mb-medium align-self-lg-start">
                  <IoLogoBuffer className="service__icon working__icon " />
                </div>

                <h2 className="service__heading mb-small working__heading">
                  Lets Walk
                </h2>
                <p className="service__description mb-small working__description">
                Are you willing to work with us? Then let's meet over a cup of coffee at our office. Fill in the form and we will get back to you. 
                </p>
                {/* <button className="service__btn working__btn small_btn">
                  Know more <MdArrowForward className="arrow" />{" "}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="portfolio">
        <div className="row justify-content-center">
          <h1 className="heading-title">Our portfolio</h1>
        </div>

        <div className="container portfolio__container">
          <div className=" portfolio__row">
            <Tabs
              defaultActiveKey="3"
              tabPosition={slideloc}
              style={{ height: 220 }}
            >
              <TabPane
                tab={
                  <span className="font-weight-bold text-left tab-item">
                    {" "}
                    Mobile UI
                  </span>
                }
                key="1"
              >
                Mobile UI
              </TabPane>
              <TabPane
                tab={
                  <span className="font-weight-bold text-left tab-item">
                    Graphics
                  </span>
                }
                key="2"
              >
                Graphics
              </TabPane>
              <TabPane
                style={{ width: "80%", margin: "0 auto" }}
                tab={
                  <span className="font-weight-bold text-left tab-item">
                    Website
                  </span>
                }
                key="3"
              >
                <Carousel
                  arrowLeft={
                    <IoIosArrowDropleft
                      style={{ fontSize: "6rem", color: "#1975ff" }}
                    />
                  }
                  arrowRight={
                    <IoIosArrowDropright
                      style={{ fontSize: "6rem", color: "#1975ff" }}
                    />
                  }
                  addArrowClickHandler
                  slidesPerPage={slides}
                  slidesPerScroll={slides}
                  arrows
                >
                  <div className="card shadow-lg">
                    <img
                      className="card-img-top"
                      src={traveleasygo}
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Travel</h5>
                      <div className="d-flex justify-content-between">
                        <p>description</p>
                        <MdArrowForward className="service_icon" />
                      </div>
                    </div>
                  </div>
                  <div className="card shadow-lg">
                    <img
                      className="card-img-top"
                      src="https://ttravel.com.au/wp-content/uploads/travel_destination_maldives-1920x1250.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Travel</h5>
                      <div className="d-flex justify-content-between">
                        <p>description</p>
                        <MdArrowForward className="service_icon" />
                      </div>
                    </div>
                  </div>
                  <div className="card shadow-lg">
                    <img
                      className="card-img-top"
                      src="https://ttravel.com.au/wp-content/uploads/travel_destination_maldives-1920x1250.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Travel</h5>
                      <div className="d-flex justify-content-between">
                        <p>description</p>
                        <MdArrowForward className="service_icon" />
                      </div>
                    </div>
                  </div>
                </Carousel>
              </TabPane>
              <TabPane
                tab={
                  <span className="font-weight-bold text-left tab-item">
                    Web App
                  </span>
                }
                key="4"
              >
                Web App
              </TabPane>
              <TabPane
                tab={
                  <span className="font-weight-bold text-left tab-item">
                    Branding
                  </span>
                }
                key="5"
              >
                Branding
              </TabPane>
            </Tabs>
          </div>
        </div>
      </section> */}
{/* 
      <section className="clients_say">
        <div className="row justify-content-center">
          <h1 className="heading-title">Clients say</h1>
        </div>

        <div className="container">
          <Carousel
            slidesPerScroll={1}
            slidesPerPage={1}
            autoPlay={5000}
            animationSpeed={1000}
            infinite
            // arrowLeft={
            //   <IoIosArrowDropleft
            //     style={{ fontSize: "6rem", color: "#1975ff" }}
            //   />
            // }
            // arrowRight={
            //   <IoIosArrowDropright
            //     style={{ fontSize: "6rem", color: "#1975ff" }}
            //   />
            // }
            arrows
          >
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                <div className="clients__bgImage"></div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 clients__comment">
                <div className="commentBox">
                  <MdFormatQuote className="commentBox__icon" />
                  <p className="commentBox__comment">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Deserunt oluptate omnisluptas minima quibusdam doloribus
                    quisquam!
                  </p>
                  <h6 className="commentBox__name">
                    {" "}
                    <span>-</span> Rajneesh
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                <div className="clients__bgImage2"></div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 clients__comment">
                <div className="commentBox">
                  <MdFormatQuote className="commentBox__icon" />
                  <p className="commentBox__comment">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Deserunt oluptate omnisluptas minima quibusdam doloribus
                    quisquam!
                  </p>
                  <h6 className="commentBox__name">
                    {" "}
                    <span>-</span> Rajneesh
                  </h6>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section> */}
      {/* <section className="clients">
        <div className="row justify-content-center">
          <h1 className="heading-title">Our clients </h1>
        </div>

        <div className="container">
          <div className="row p-5 justify-content-md-around align-items-center">
            <img src={logo} className="logo" />
            <img src={logo} className="logo" />
            <img src={logo} className="logo" />
            <img src={logo} className="logo" />
          </div>
        </div>
      </section> */}

      {/* <section className="get_in_touch">
        <div className="container">
          <div className="row get_in_touch__row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div
                className="row justify-content-center"
                style={{ marginTop: "10rem" }}
              >
                <h1 className="heading-title">Get in touch </h1>
              </div>
              <form className="form">
                <div className="row px-5 py-4">
                  <div className="col-sm-6">
                    <input
                      placeholder="Name"
                      type="text"
                      required
                      className="form-control form-control-lg form-input"
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      placeholder="Email"
                      required
                      type="email"
                      className="form-control form-control-lg form-input"
                    />
                  </div>
                </div>
                <div className="row py-4 px-5">
                  <div className="col">
                    <input
                      placeholder="Subject"
                      required
                      type="text"
                      className="form-control form-control-lg form-input"
                    />
                  </div>
                </div>
                <div className="row py-4 px-5">
                  <div className="col">
                    <textarea
                      placeholder="Message"
                      required
                      rows="5"
                      className="form-control form-control-lg form-input"
                      style={{ resize: "none" }}
                    />
                  </div>
                </div>
                <div className="row py-4 px-5">
                  <div className="col text-center">
                    <button className="service__btn working__btn small_btn">
                      Submit message
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12 col-md-6 get_in_touch__rightbox d-none  d-md-block"></div>
          </div>
        </div>
      </section> */}
      {/* <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 6322.7 1041"
        className="svg-bg"
      >
        <path
          className="st0"
          d="M5600.8,594.2c-205.7,146.8-315.5,217.4-562.7,217.4c-247.2,0-357-70.6-562.7-217.4
	c-228.8-163.3-542.2-387.1-1130.8-387.1S2442.8,430.8,2214,594.2c-205.7,146.8-315.5,217.4-562.6,217.4
	c-247.2,0-357-70.6-562.6-217.4C873.3,440.4,582.9,233.1,58.4,209.4C3.9,206.9-42,239.3-42,279.7v1073.6v70h6364.7V924.4V248.1
	C5984.9,320.2,5769.8,473.6,5600.8,594.2z"
        />
      </svg> */}
    </Layout>
  )
}

export default IndexPage
